<template>
  <div class="pl-8 pr-8">
    <div class="mb-xl-margin-file-sign">
      <v-row class="mt-10">
        <v-col class="text-left navidad-text" cols="6">
          <h1 class="reference__title">Reporte ingreso OKAN</h1>
        </v-col>
      </v-row>
      <v-card outlined class="reference__card px-8 mt-8" elevation="0">
        <!-- Filtros -->
        <v-row class="mt-2">
          <v-col cols="6">
            <v-autocomplete
              :items="costCenters"
              label="Centro de costos"
              v-model="costCentersId"
              item-text="cc"
              item-value="cc"
              multiple
              @change="handleCostCenterChange"
            >
              <template v-slot:prepend-inner>
                <v-icon color="#afb0b0"> mdi-filter-outline </v-icon>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              :items="subCostCenters"
              label="Sub Centro de Costos"
              v-model="subCostCentersId"
              item-text="cod_scc"
              item-value="cod_scc"
              multiple
              @change="handleSubCostCenterChange"
              :disabled="subCostCenters.length === 0"
            >
              <template v-slot:prepend-inner>
                <v-icon color="#afb0b0"> mdi-filter-outline </v-icon>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              :items="campaign"
              label="Campaña"
              v-model="campaignId"
              item-text="campaign"
              item-value="campaign"
              multiple
              @change="handleCampaing"
              :disabled="campaign.length === 0"
            >
              <template v-slot:prepend-inner>
                <v-icon color="#afb0b0"> mdi-filter-outline </v-icon>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="6">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="150"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="selectedDate"
                  label="Seleccionar fecha"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="selectedDate"
                :max="maxDate"
                :min="minDate"
              >
                <v-btn
                  text
                  color="primary"
                  @click="(menu = false), (selectedDate = null)"
                >
                  Cancelar
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="6">
            <v-autocomplete
              prepend-inner-icon="mdi-account-outline"
              color="#466be3"
              :items="listUserAll"
              v-model="listUserAllId"
              item-text="user"
              item-value="document_number"
              label="Nombre - Número de cédula - Usuario de red"
              multiple
            >
              <template v-slot:selection="data">
                <v-chip small dark v-bind="data.attrs" color="#466be3">
                  {{ data.item.user }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="6">
            <v-hover v-slot="{ hover }">
              <v-btn
                @click="download()"
                block
                rounded
                style="color: #fff"
                :style="{
                  'background-color': hover ? '#324CA1' : '#466BE3',
                }"
                class="mt-2 ml-2"
              >
                Descargar
              </v-btn>
            </v-hover>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <Alert
      :open="message.dialog"
      :text="message.sms"
      :title="message.title"
      :type="message.type"
      :redirect="message.redirect"
      @close="message.dialog = false"
    >
    </Alert>
  </div>
</template>
<script>
import color from "@/mixins/color";
import Api from "@/utils/api";
import Alert from "../../components/Alert.vue";
export default {
  components: {
    Alert,
  },
  mixins: [color],
  data() {
    return {
      message: {
        dialog: false,
        title: "",
        sms: "",
        type: "",
        redirect: "",
      },
      loading: {
        table: false,
      },
      costCenters: [],
      costCentersId: [],
      subCostCenters: [],
      subCostCentersId: [],
      campaign: [],
      campaignId: [],
      selectedDate: new Date().toISOString().substr(0, 10),
      listUserAll: [],
      listUserAllId: [],
      menu: false,
      minDate: new Date(new Date().setDate(new Date().getDate() - 100))
        .toISOString()
        .substr(0, 10),
      maxDate: new Date().toISOString().substr(0, 10),
    };
  },
  methods: {
    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");

      this.token = tokenb64;
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
        // console.log(JSON.parse(atob(div[1])));
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    download() {
      var data = {
        cc: this.mapingDataCc(),
        scc: this.mapingDataScc(),
        campain: this.mapingDataCampaing(),
        cedula: this.listUserAllId,
        fecha: this.selectedDate,
      };
      this.loadingAll = true;
      Api.Auth()
        .downloadExcelLogsLogin(this.token, data)
        .then((res) => {
          if (res.data.cod == 0) {
            this.message.dialog = true;
            this.message.sms = res.data.message;
            this.message.title = "Excelente";
            this.message.type = "success";
            window.open(res.data.data, "_blank");
          }
        })
        .catch((error) => {
          console.log(error.response.status);
          this.message.dialog = true;
          if (error.response.status == 404) {
            this.message.sms =
              "Aun no se han cargado condiciones de comisiones";
            this.message.title = "¡Oh no!";
            this.message.type = "error";
          } else {
            this.message.sms = error.response.data.message;
            this.message.title = "¡Oh no!";
            this.message.type = "error";
          }
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    listCostCenter() {
      Api.Auth()
        .listCostCenter(this.token)
        .then((res) => {
          this.costCenters = [{ cc: "Todos" }, ...res.data.data];
        })
        .catch((error) => {
          console.log(error);
        });
    },
    listSubCostCenter() {
      var data = {
        cc: this.mapingDataCc(),
      };
      if (this.costCentersId.length === 0) {
        this.subCostCenters = [];
        this.campaign = [];
      } else {
        Api.Auth()
          .listSubCostCenter(this.token, data)
          .then((res) => {
            this.subCostCenters = [{ cod_scc: "Todos" }, ...res.data.data];
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    listCampaign() {
      if (this.subCostCentersId.length == 0) {
        this.campaign = [];
      } else {
        var data = {
          cc: this.mapingDataCc(),
          scc: this.mapingDataScc(),
        };
        Api.Auth()
          .listCampaign(this.token, data)
          .then((res) => {
            this.campaign = res.data.data.filter(
              (item) => item.campaign !== null
            );

            if (!this.campaignId.includes("Todos") && this.campaign.length !=0 ) {
              this.campaign.unshift({ campaign: "Todos" });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    listUsers() {
      let AllUser = this.$store.getters.getAllUsersNickName;
      this.listUserAll = AllUser;
    },
    handleCostCenterChange() {
      if (this.costCentersId.includes("Todos")) {
        this.costCentersId = ["Todos"];
      }
      this.listSubCostCenter();
    },
    handleSubCostCenterChange() {
      if (this.subCostCentersId.includes("Todos")) {
        this.subCostCentersId = ["Todos"];
      }
      this.listCampaign();
    },
    handleCampaing() {
      if (this.campaignId.includes("Todos")) {
        this.campaignId = ["Todos"];
      }
    },
    mapingDataCc() {
      const isTodosSelected = this.costCentersId.includes("Todos");
      if (isTodosSelected) {
        return this.costCenters
        .map((costCenter) => costCenter.cc)
        .filter((costCenter) => costCenter !== "Todos");
      } else {
        return this.costCentersId;
      }
    },
    mapingDataScc() {
      const isTodosSelected = this.subCostCentersId.includes("Todos");
      if (isTodosSelected) {
        return this.subCostCenters
        .map((subCostCenter) => subCostCenter.cod_scc)
        .filter((subCostCenter) => subCostCenter !== "Todos");
      } else {
        return this.subCostCentersId;
      }
    },
    mapingDataCampaing() {
      const isTodosSelected = this.campaignId.includes("Todos");
      if (isTodosSelected) {
        return this.campaign
        .map( (campaign) => campaign.campaign);
      } else {
        return this.campaignId;
      }
    },
  },
  created() {
    this.getToken();
    this.listCostCenter();
    setTimeout(() => {
      this.listUsers();
    }, 7000);
  },
};
</script>
<style>
@import "./../../assets/css/main.less";

.navidad-text {
  display: flex;
}

.reference__table .v-data-table-header {
  font-family: "RobotoRegular";
  background-color: #f2f4ff;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 19px;
  color: #353535 I !important;
}

tr,
th,
span .reference__table .text-start .sortable {
  font-size: 16px !important;
}

.reference__card {
  border-radius: 15px !important;
}

.reference__title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}

.references__header {
  color: #353535 !important;
}
</style>
